import React from 'react';
import { BIProviderPropsMap } from './interfaces';

export interface IBIContext extends BIProviderPropsMap {}

export const BIContext = React.createContext<IBIContext>({
  ownerId: '',
  startTimeOfRender: 0,
  beforeAndAfterAction: null,
  afterAction: null,
  memberWebAppButtonClick: null,
  screenOpen() {},
  tabOpen: null,
  reportSSRIsRendered(): void {},
  quizCheckSnapshot(): void {},
});

export const useMemberBI = () => React.useContext(BIContext);

export const SettingsConsumer = BIContext.Consumer;
