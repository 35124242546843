import React from 'react';
import {
  FeedbackItem,
  ListParticipantStepsResponse,
  ParticipantSection,
  ParticipantStepStateTransition,
  V1ParticipantStep,
} from '@wix/ambassador-challenge-service-web/types';

export interface IParticipantStepsContext {
  participantSteps: ListParticipantStepsResponse;
  updateParticipantSteps(
    currentDate?: Date,
    includeAll?: boolean,
  ): Promise<void>;
  isParticipantStepsLoading: boolean;
  updateParticipantStepStatus(
    sections: ParticipantSection[],
    steps: V1ParticipantStep[],
    stepId: string,
    transitions?: ParticipantStepStateTransition[],
    feedbackItems?: FeedbackItem[],
  ): void;
}

export const ParticipantStepsContext = React.createContext<IParticipantStepsContext>(
  {
    participantSteps: null,
    updateParticipantSteps: null,
    isParticipantStepsLoading: false,
    updateParticipantStepStatus: null,
  },
);
export const useParticipantSteps = () =>
  React.useContext(ParticipantStepsContext);

export const ParticipantStepsConsumer = ParticipantStepsContext.Consumer;
