import React from 'react';
import { FullscreenContext, IFullscreenContext } from './FullscreenContext';

export interface IFullscreenProviderProps extends IFullscreenContext {}

const toggleFullscreenMode = async (
  element: HTMLElement,
  isFullscreenMode: boolean,
) => {
  if (isFullscreenMode) {
    element.style.overflow = 'unset';
    await document.exitFullscreen();
  } else if (element) {
    element.style.overflow = 'auto';
    await element.requestFullscreen();
  }
};

export const FullscreenProvider: React.FC<IFullscreenProviderProps> = (
  props,
) => {
  const [isFullscreenMode, setIsFullscreenMode] = React.useState<boolean>(
    false,
  );

  React.useEffect(() => {
    document.onfullscreenchange = (_event) => {
      setIsFullscreenMode(!!document.fullscreenElement);
    };
  }, []);

  return (
    <FullscreenContext.Provider
      value={{
        toggleFullscreen: async (element) => {
          await toggleFullscreenMode(element, isFullscreenMode);
        },
        isFullscreen: isFullscreenMode,
      }}
    >
      {props.children}
    </FullscreenContext.Provider>
  );
};

FullscreenProvider.displayName = 'Fullscreen Provider';
