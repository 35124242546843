import React from 'react';
import { FeedbackItem } from '@wix/ambassador-challenge-service-web/types';

export interface IResolveStepContext {
  isResolveStepRequestInProgress: boolean;
  resolveStep(
    stepId: string,
    feedbackItems?: FeedbackItem[],
    isUpdate?: boolean,
  ): Promise<void>;
  resolveStepError: string;
}

export const ResolveStepContext = React.createContext<IResolveStepContext>({
  isResolveStepRequestInProgress: false,
  resolveStep: (() => {}) as any,
  resolveStepError: null,
});

export const useResolveStep = () => React.useContext(ResolveStepContext);

export const ResolveStepConsumer = ResolveStepContext.Consumer;
