import React from 'react';
import { GetChallengeResponse } from '@wix/ambassador-challenge-service-web/types';

export interface IChallengeDataContext {
  isGroupsInstalled: boolean;
  challengeData: GetChallengeResponse;
}

export const ChallengeDataContext = React.createContext<IChallengeDataContext>({
  challengeData: null,
  isGroupsInstalled: null,
});

export function useChallengeData(): IChallengeDataContext {
  return React.useContext(ChallengeDataContext);
}

export const ChallengeDataConsumer = ChallengeDataContext.Consumer;
