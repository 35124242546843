export const PREFIX_MAP = {
  Plural: '_plural',
  Single: '',
  Many: '_many',
};

const LOCALES_TO_INCLUDE_MANY = ['ru', 'pl', 'cs', 'uk', 'bg'];

export function getCountPrefix(count: number, lang: string) {
  const lastDigit = count % 10;
  const isCountryIncluded = LOCALES_TO_INCLUDE_MANY.includes(lang);

  if (count === 1) {
    return PREFIX_MAP.Single;
  }

  if (count < 10 && count > 5) {
    return PREFIX_MAP.Plural;
  }

  if (count >= 10 && count <= 20) {
    return PREFIX_MAP.Plural;
  }

  if (lastDigit === 0) {
    return PREFIX_MAP.Plural;
  }

  if (isCountryIncluded && lastDigit === 1) {
    return PREFIX_MAP.Single;
  }

  if (isCountryIncluded && lastDigit > 1 && lastDigit < 5) {
    return PREFIX_MAP.Many;
  }

  return PREFIX_MAP.Plural;
}
