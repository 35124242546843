import {
  ListParticipantStepsResponse,
  ParticipantSection,
  ParticipantStepState,
  V1Challenge,
  V1Participant,
  V1ParticipantStep,
} from '@wix/ambassador-challenge-service-web/types';
import { CompletedIcon } from '../../../../components-shared/CompletedIcon';
import { MissedIcon } from '../../../../components-shared/MissedIcon';
import { LockedIcon } from '../../../../components-shared/LockedIcon';
import { RunningIcon } from '../../../../components-shared/RunningIcon';
import {
  getSelfPacedSettings,
  isFlexible,
  isSelfPaced,
} from '../../../../selectors/challenges';
import isSameDay from 'date-fns/isSameDay';
import flatten from 'lodash/flatten';

import { getRightDateFromBackend } from '../../../../selectors/dates';
import { IQuestionnaireSend } from '../components/Questionnaire/interfaces';
import { ISettingsContextValue } from '@wix/yoshi-flow-editor/tpa-settings';
import challengeSettings from '../../settingsParams';
import { isAfter } from 'date-fns';
import userTypeHandlers from '../../../../contexts/User/helpers/userTypeHandlers';

const STEP_TOOLTIP_KEY = {
  [ParticipantStepState.COMPLETED]: 'step.tooltip.completed',
  [ParticipantStepState.OVERDUE]: 'step.tooltip.missed',
  [ParticipantStepState.PENDING]: 'step.tooltip.pending-scheduled',
  [ParticipantStepState.RUNNING]: 'step.tooltip.running',
  'PENDING-SPC': 'step.tooltip.pending-spc',
  'PENDING-SCHEDULED': 'step.tooltip.pending-scheduled',
  STEP_COMPLETION_DISABLED: 'step.tooltip.complete-not-allowed',
};

const STEP_INDICATOR = {
  [ParticipantStepState.COMPLETED]: CompletedIcon,
  [ParticipantStepState.OVERDUE]: MissedIcon,
  [ParticipantStepState.PENDING]: LockedIcon,
  [ParticipantStepState.RUNNING]: RunningIcon,
  STEP_COMPLETION_DISABLED: LockedIcon,
};

export enum ChallengeEntityType {
  STEP = 'STEP',
  SECTION = 'SECTION',
  VOID = 'VOID',
}

export interface INextEntity {
  section?: ParticipantSection;
  step?: V1ParticipantStep;
  type: ChallengeEntityType;
}

export interface ISiteColor {
  name: string;
  value: string;
  reference: string;
}
const addTransparentBg = (colors: ISiteColor[]): ISiteColor[] => {
  return colors.map((color) => {
    // if (color?.name === 'color_11') {
    //   return {
    //     ...color,
    //     value: 'transparent',
    //   };
    // }

    return color;
  });
};

const getBaseViewOptions = (challenge: V1Challenge) => {
  const showHeaderDate = !isFlexible(challenge) && !isSelfPaced(challenge);

  const stepsCount = challenge.stepsSummary.stepsNumber;
  const showSteps = !!stepsCount;
  const showDuration =
    isFlexible(challenge) ||
    (isSelfPaced(challenge) && getSelfPacedSettings(challenge)?.duration);
  const showParticipants = !!challenge.participantsSummary.participantsNumber;

  const overviewItemsCount =
    Number(showSteps) + Number(showDuration) + Number(showParticipants);

  return {
    showHeaderDate,
    showSteps,
    stepsCount,
    showDuration,
    showParticipants,
    overviewItemsCount,
  };
};

const getBaseViewOptionsForList = (
  challenge: V1Challenge,
  settings: ISettingsContextValue,
) => {
  const {
    showHeaderDate,
    showSteps,
    stepsCount,
    showDuration,
    showParticipants,
    overviewItemsCount,
  } = getBaseViewOptions(challenge);

  return {
    showHeaderDate,
    showSteps:
      showSteps &&
      settings.get(challengeSettings.listLayoutDisplayChallengeSteps),
    stepsCount,
    showDuration,
    showParticipants:
      showParticipants &&
      settings.get(challengeSettings.listLayoutDisplayChallengeParticipants),
    overviewItemsCount,
  };
};

const getBaseViewOptionsForSidebar = (
  challenge: V1Challenge,
  settings: ISettingsContextValue,
) => {
  const {
    showHeaderDate,
    showSteps,
    stepsCount,
    showDuration,
    showParticipants,
    overviewItemsCount,
  } = getBaseViewOptions(challenge);

  return {
    showHeaderDate,
    showSteps:
      showSteps &&
      settings.get(challengeSettings.sidebarLayoutDisplayChallengeSteps),
    stepsCount,
    showDuration:
      showDuration &&
      settings.get(challengeSettings.sidebarLayoutDisplayChallengeDuration),
    showParticipants:
      showParticipants &&
      settings.get(challengeSettings.sidebarLayoutDisplayChallengeParticipants),
    overviewItemsCount,
  };
};

const getResolveStepData = (
  currentStep: V1ParticipantStep,
  data: IQuestionnaireSend = {},
  savedQuestionnaireData: any = {},
) => {
  if (!savedQuestionnaireData) {
    savedQuestionnaireData = {};
  }

  const isStepResolved = utils.isStepResolved(currentStep);
  const currentStepAfterUpdate = { ...(currentStep || {}) };
  const isFeedbackSettled =
    !!data ||
    Object.keys(savedQuestionnaireData).filter(
      (key) => savedQuestionnaireData[key],
    ).length;
  const savedItems = Object.keys(savedQuestionnaireData).reduce(
    (accumulator, key) => {
      const items = savedQuestionnaireData[key];

      if (items) {
        Object.keys(items).forEach((itemKey) => {
          accumulator[itemKey] = items[itemKey];
        });
      }

      return accumulator;
    },
    {},
  );
  const feedbackItems = isFeedbackSettled
    ? Object.keys({ ...data, ...savedItems }).map((fieldId) => {
        const f = data[fieldId] || savedItems[fieldId];

        return {
          feedbackItemSettingsId: fieldId,
          [f.type]: f.value,
        };
      })
    : null;

  if (!isStepResolved) {
    currentStepAfterUpdate.transitions = [
      {
        state: ParticipantStepState.COMPLETED,
        occurredAt: new Date(),
      },
    ];
  }
  if (isFeedbackSettled) {
    currentStepAfterUpdate.feedback = {
      items: feedbackItems,
    };
  }

  return {
    currentStepAfterUpdate,
    feedbackItems,
    isStepResolved,
  };
};

const isParticipantCompletedChallenge = (
  participant: V1Participant,
): boolean => {
  return userTypeHandlers.isCompleted(participant?.transitions?.[0]?.state);
};

const getStepsResolvedValue = (steps: V1ParticipantStep[]) => {
  return Math.floor(
    (steps.reduce(
      ((resolved: number, step: V1ParticipantStep) => {
        return utils.isStepResolved(step) ? ++resolved : resolved;
      }) as any,
      0,
    ) /
      (steps.length || 1)) *
      100,
  );
};

const getFirstAvailableStep = (
  flatStepsList: V1ParticipantStep[] = [],
): V1ParticipantStep => {
  return (
    flatStepsList.find((step) => {
      return utils.isStepAvailableForComplete(step);
    }) ||
    flatStepsList.find((step) => {
      return utils.isStepOverdue(step);
    })
  );
};

const getLastCompletedStep = (
  flatStepsList: V1ParticipantStep[] = [],
): V1ParticipantStep => {
  return flatStepsList
    .slice(0)
    .reverse()
    .find((step) => {
      return utils.isStepResolved(step);
    });
};

const isStepHidden = (
  challenge: V1Challenge,
  flatStepsList: V1ParticipantStep[], // should be sorted properly for the SPC
  step: V1ParticipantStep,
  isExperimentEnabled: boolean,
) => {
  const isSPC = isSelfPaced(challenge);
  const isResolveStepsInOrderSettingEnabled = !!challenge?.settings
    ?.accessRestrictions?.resolveStepsInOrder;
  const isHideFutureStepsSettingEnabled = !!challenge?.settings
    ?.accessRestrictions?.hideFutureSteps;
  const isStepResolved = utils.isStepResolved(step);

  const isSPCStepFirstAvailable =
    getFirstAvailableStep(flatStepsList)?.id === step?.id;
  const isScheduledStepInTheFuture = isAfter(
    getRightDateFromBackend(step?.dateFrame?.start),
    new Date(),
  );

  const isRequirementsForHideStepComplied =
    isExperimentEnabled && !isStepResolved;

  return isSPC
    ? isRequirementsForHideStepComplied &&
        !isSPCStepFirstAvailable &&
        isResolveStepsInOrderSettingEnabled &&
        isHideFutureStepsSettingEnabled
    : isRequirementsForHideStepComplied &&
        isHideFutureStepsSettingEnabled &&
        isScheduledStepInTheFuture;
};

const isVisibleStepLockedForComplete = (
  challenge: V1Challenge,
  flatStepsList: V1ParticipantStep[], // should be sorted properly for the SPC
  step: V1ParticipantStep,
  isExperimentEnabled: boolean,
) => {
  const isSPC = isSelfPaced(challenge);
  const isResolveStepsInOrderSettingEnabled = !!challenge?.settings
    ?.accessRestrictions?.resolveStepsInOrder;
  const isHideFutureStepsSettingEnabled = !!challenge?.settings
    ?.accessRestrictions?.hideFutureSteps;
  const isStepResolved = utils.isStepResolved(step);

  const isSPCStepFirstAvailable =
    getFirstAvailableStep(flatStepsList)?.id === step?.id;

  /*
    For SPC we can set `resolve steps in order` but without hiding further steps.
   */

  return (
    isExperimentEnabled &&
    !isStepResolved &&
    isSPC &&
    !isSPCStepFirstAvailable &&
    isResolveStepsInOrderSettingEnabled &&
    !isHideFutureStepsSettingEnabled
  );
};

type ChallengeStepData = {
  listParticipantSections: ParticipantSection[];
  participantSteps: ListParticipantStepsResponse;
};
const utils = {
  STEP_TOOLTIP_KEY,
  addTransparentBg,
  getBaseViewOptions,

  getStepTooltipKey: (
    step: V1ParticipantStep,
    isUnavailable: boolean,
    isSPC: boolean,
    participant: V1Participant,
  ) => {
    if (
      (utils.isStepOverdue(step) ||
        utils.isStepPending(step) ||
        utils.isStepRunning(step)) &&
      utils.isParticipantCompletedChallenge(participant)
    ) {
      return STEP_TOOLTIP_KEY.STEP_COMPLETION_DISABLED;
    }

    if (isUnavailable) {
      return isSPC
        ? STEP_TOOLTIP_KEY['PENDING-SPC']
        : STEP_TOOLTIP_KEY['PENDING-SCHEDULED'];
    }

    if (utils.isStepPending(step)) {
      return isSameDay(new Date(step?.dateFrame?.start), new Date())
        ? STEP_TOOLTIP_KEY.RUNNING
        : isSPC
        ? STEP_TOOLTIP_KEY['PENDING-SPC']
        : STEP_TOOLTIP_KEY['PENDING-SCHEDULED'];
    }

    return STEP_TOOLTIP_KEY[step.transitions[0].state];
  },

  getStepIcon: (
    step: V1ParticipantStep,
    isUnavailable: boolean,
    participant: V1Participant,
  ) => {
    if (
      (utils.isStepOverdue(step) ||
        utils.isStepRunning(step) ||
        utils.isStepPending(step)) &&
      utils.isParticipantCompletedChallenge(participant)
    ) {
      return STEP_INDICATOR.STEP_COMPLETION_DISABLED;
    }
    return isUnavailable
      ? STEP_INDICATOR[ParticipantStepState.PENDING]
      : utils.isStepAvailableForComplete(step)
      ? STEP_INDICATOR[ParticipantStepState.RUNNING]
      : STEP_INDICATOR[step.transitions[0].state];
  },

  getBaseViewOptionsForList,
  getBaseViewOptionsForSidebar,
  isStepRunning: (step: V1ParticipantStep): boolean => {
    return step?.transitions?.['0']?.state === ParticipantStepState.RUNNING;
  },

  isStepPending: (step: V1ParticipantStep): boolean => {
    return step?.transitions?.['0']?.state === ParticipantStepState.PENDING;
  },

  isStepResolved: (step: V1ParticipantStep): boolean => {
    // changed

    return step?.transitions?.['0']?.state === ParticipantStepState.COMPLETED;
  },

  isStepOverdue: (step: V1ParticipantStep): boolean => {
    return step?.transitions?.['0']?.state === ParticipantStepState.OVERDUE;
  },

  isStepLocked: (step: V1ParticipantStep) => {
    return !utils.isStepAvailableForComplete(step) && utils.isStepPending(step);
  },

  isStepAvailableForComplete: (step: V1ParticipantStep) => {
    return (
      step?.transitions?.['0'] &&
      (utils.isStepRunning(step) ||
        (utils.isStepPending(step) &&
          isSameDay(getRightDateFromBackend(step.dateFrame.start), new Date())))
    );
  },

  isFeedbackFormRequired: (step: V1ParticipantStep) => {
    const challengeStepIndividualSettings =
      step?.source?.settings?.general?.individual;
    const isQuestionnaireRequired =
      challengeStepIndividualSettings.confirmationRequired &&
      Object.keys(challengeStepIndividualSettings?.feedbackSettings).length;
    const isQuizRequired =
      challengeStepIndividualSettings.showQuiz &&
      Object.keys(challengeStepIndividualSettings?.quizSettings).length;

    return !!(
      challengeStepIndividualSettings &&
      (!!isQuestionnaireRequired || !!isQuizRequired)
    );
  },

  getResolveStepData,

  isParticipantCompletedChallenge,

  getNextEntity(stepId: string, data: ChallengeStepData): INextEntity {
    if (data.listParticipantSections.length) {
      const nextSection = data.listParticipantSections.reduce(
        (_nextSection, section, index, sections) => {
          if (section.steps[section.steps.length - 1]?.id === stepId) {
            return sections[index + 1];
          }
          return _nextSection;
        },
        undefined,
      );

      if (nextSection) {
        return {
          section: nextSection,
          type: ChallengeEntityType.SECTION,
        };
      }
    }
    const nextStep = utils.getNextStep(stepId, utils.getFlatStepsList(data));

    if (nextStep) {
      return {
        step: nextStep,
        type: ChallengeEntityType.STEP,
      };
    }

    return {
      type: ChallengeEntityType.VOID,
    };
  },

  getNextButtonLabel(entity: ChallengeEntityType): string {
    switch (entity) {
      case ChallengeEntityType.STEP:
        return 'challenge.page.next-step';
      case ChallengeEntityType.SECTION:
        // return 'challenge.page.next-section'; //todo once localization will be done uncomment
        return 'challenge.page.steps.continue';
      case ChallengeEntityType.VOID:
        return '';
    }
  },

  getFlatStepsList(data: ChallengeStepData) {
    // both for the sections and without them
    const {
      listParticipantSections = [],
      participantSteps: { steps = [] } = {},
    } = data;
    const isSections = listParticipantSections.length;

    return isSections
      ? flatten(listParticipantSections.map((section) => section.steps || []))
      : steps;
  },

  getNextStep(stepId: string, steps: V1ParticipantStep[] = []) {
    const currentStepInd = steps.findIndex((step) => step.id === stepId);

    return steps[currentStepInd + 1] || null;
  },

  getStepsResolvedValue,

  scrollToChallengePage: () => {
    const challengePageDataHook = 'challenge-page';

    document
      .querySelector(`[data-hook="${challengePageDataHook}"]`)
      ?.scrollIntoView({
        behavior: 'smooth',
      });
  },

  getHeaderAlignByType: (
    type: 'List' | 'Sidebar',
    settings: ISettingsContextValue,
  ) => {
    switch (type) {
      case 'Sidebar':
        return settings.get(challengeSettings.sidebarLayoutTextAlignment);
      case 'List':
      default:
        return settings.get(challengeSettings.listLayoutHeaderAlignment);
    }
  },

  getContentAlignByType: (
    type: 'List' | 'Sidebar',
    settings: ISettingsContextValue,
  ) => {
    switch (type) {
      case 'Sidebar':
        return settings.get(challengeSettings.sidebarLayoutTextAlignment);
      case 'List':
      default:
        return settings.get(challengeSettings.listLayoutContentAlignment);
    }
  },

  getFirstAvailableStep,
  getLastCompletedStep,

  isStepHidden,
  isVisibleStepLockedForComplete,
};

export default utils;
