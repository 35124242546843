import React from 'react';
import { ParticipantSection } from '@wix/ambassador-challenge-service-web/types';

export interface IParticipantSectionsContext {
  isListParticipantSectionsRequestInProgress: boolean;
  listParticipantSections: ParticipantSection[];
  getParticipantSections(): Promise<ParticipantSection[]>;
  updateParticipantSections(): Promise<void>;
}

export const ParticipantSectionsContext = React.createContext<IParticipantSectionsContext>(
  {
    isListParticipantSectionsRequestInProgress: false,
    listParticipantSections: null,
    getParticipantSections: null,
    updateParticipantSections: null,
  },
);

export const useSections = () => React.useContext(ParticipantSectionsContext);

export const ParticipantSectionsConsumer = ParticipantSectionsContext.Consumer;
