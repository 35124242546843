import { IWidgetControllerConfig } from '@wix/native-components-infra/dist/src/types/types';

export enum ButtonNames {
  OneAppInfo = 'one_app_info',
  ViewChallenge = 'viewChallenge',
  BackToChallenge = 'backToChallenge',
  LoginAndJoinToTheChallenge = 'loginAndJoinToTheChallenge',
  JoinToTheChallenge = 'joinToTheChallenge',
  GoToOneApp = 'goToOneApp',
  OpenDotsMenu = 'OpenDotsMenu',
  StepComplete = 'StepComplete',
  LeaveTheChallenge = 'LeaveTheChallenge',
  LeaveTheChallengeAtModal = 'LeaveTheChallengeModal',
  CancelLeaveTheChallengeModal = 'CancelLeaveTheChallengeModal',
  StartDateSelection = 'StartDateSelection',
  ShareCompletedStep = 'ShareCompletedStep',
  ShareFacebook = 'ShareFacebook',
  ShareTwitter = 'ShareTwitter',
  ShareLinkedin = 'ShareLinkedin',
  CopyLink = 'CopyLink',
  CloseModalButton = 'CloseModalButton',
  OverviewTileSelection = 'OverviewSelection',
  StepTileSelection = 'StepTileSelection',
  NextStep = 'NextStep',
  NextSection = 'NextSection',
  toggleFullscreenMode = 'toggleFullscreenMode',
  GotItFromCantJoinModal = 'GotItFromCantJoinmodal',
  OtherPaymentMethodsFromCantJoinModal = 'OtherPaymentMethodsFromCantJoinModal',

  SeeFeedback = 'SeeFeedback',
  QstnSkipModal = 'QstnSkipModal',
  QstnCancelModal = 'QstnCancelModal',
  QstnSendModal = 'QstnSendModal',
  QstnAddTextResponseModal = 'QstnAddTextResponseModal',
  QstnClearTextResponseModal = 'QstnClearTextResponseModal',
  QstnAddQuantityResponseModal = 'QstnAddQuantityResponseModal',
  QstnClearQuantityResponseModal = 'QstnClearQuantityResponseModal',
  QstnAddMediaResponseModal = 'QstnAddMediaResponseModal',
  QstnClearMediaResponseModal = 'QstnClearMediaResponseModal',
  QstnAddChoiceResponseModal = 'QstnAddChoiceResponseModal',
  QstnClearChoiceResponseModal = 'QstnClearChoiceResponseModal',
  QuizCancelModal = 'QuizCancelModal',
  QuizSkipModal = 'QuizSkipModal',
  QuizCheckModal = 'QuizCheckModal',
  QuizNextModal = 'QuizNextModal',
  QuizSendModal = 'QuizSendModal',
  QuizAddChoiceResponseModal = 'QuizAddChoiceResponseModal',
  QuizClearChoiceResponseModal = 'QuizClearChoiceResponseModal',
}
export interface BIProviderPropsMap {
  ownerId: string;
  reportSSRIsRendered(): void;
  beforeAndAfterAction<T>(
    type: ActionTypes,
    fn: (actionId: string) => Promise<T>,
  ): Promise<T>;
  afterAction(type: ActionTypes, startDate?: any): Promise<any>;
  screenOpen(scrName?: ScreenNames): void;
  tabOpen(options: { tabName: TabNames; challengeId?: string }): Promise<any>;
  startTimeOfRender: number;
  setChallengeId?(chId: string): void;
  setScreenName?(scrName: ScreenNames): void;
  memberWebAppButtonClick(options: {
    buttonName: ButtonNames;
    challengeId?: string;
    isRequired?: boolean;
  }): Promise<any>;
  quizCheckSnapshot?(stepId: string, questionsJson: any): void;
}

export enum ActionTypes {
  CHALLENGE_JOIN = 'challenge_join',
  PAYMENT = 'payment_request',
  CREATE_PAYMENT_ORDER_ID = 'create_payment_order_id',
  JOIN_WITH_PAYMENT = 'join_with_payment',
  JOIN_REQUEST_CREATE = 'join_request_create',
  JOIN_REQUEST_CANCEL = 'join_request_cancel',
  STEP_COMMENT_SAVE = 'step_comment_save',
  FEEDBACK_SEND = 'feedback_send',
  FEEDBACK_SAVE = 'feedback_save',
  NOTIFICATION_SETTINGS_SET = 'notification_settings_set',
  STEP_COMPLETE = 'step_complete',
  STEP_FEEDBACK_UPDATE = 'STEP_FEEDBACK_UPDATE',
  CHALLENGE_COMPLETE = 'challenge_complete',
  CHALLENGE_LEFT = 'challenge_left',
  LEAVE_THE_CHALLENGE = 'leave_the_challenge',
  START_DATE_SELECTED = 'start_date_selected',
}

export enum ScreenNames {
  ChallengesListPage = 'ChallengesListPage',
  PaymentPage = 'PaymentPage',
  ChallengePage = 'ChallengeDetails',
  ChallengePageForParticipant = 'ChallengePageForParticipant',
  ChallengeListWidget = 'ChallengesList',
  ThankYouPage = 'ThankYouPage',
}

export enum TabNames {
  ChallengeParticipantSchedule = 'ChallengeParticipantSchedule',
  ChallengeParticipantOverview = 'ChallengeParticipantOverview',
}

export interface IBiSettings {
  screenName: ScreenNames;
  preventAutomaticallySendChallengeId?: boolean;
  getItemsCount(config: IWidgetControllerConfig): number | Promise<number>;
}
