import React from 'react';
import { IGeneralDataContext, GeneralDataContext } from './GeneralDataContext';

export const GeneralDataProvider: React.FC<IGeneralDataContext> = (props) => {
  return (
    <GeneralDataContext.Provider
      value={{
        host: props.host,
        isFullWidthLayout: props.isFullWidthLayout,
        instanceId: props.instanceId,
        instance: props.instance,
        msid: props.msid,
        defaultBIParams: props.defaultBIParams,
      }}
    >
      {props.children}
    </GeneralDataContext.Provider>
  );
};
