import React from 'react';

import { BIContext } from './BIContext';
import { BIProviderPropsMap } from './interfaces';

export const BIProvider: React.FC<BIProviderPropsMap> = (props) => {
  const { children } = props;
  return (
    <BIContext.Provider
      value={{
        ownerId: props.ownerId,
        beforeAndAfterAction: props.beforeAndAfterAction,
        afterAction: props.afterAction,
        screenOpen: props.screenOpen,
        tabOpen: props.tabOpen,
        startTimeOfRender: props.startTimeOfRender,
        memberWebAppButtonClick: props.memberWebAppButtonClick,
        reportSSRIsRendered: props.reportSSRIsRendered,
        quizCheckSnapshot: props.quizCheckSnapshot,
      }}
    >
      {children}
    </BIContext.Provider>
  );
};
